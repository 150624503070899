<template>
  <div class="bg-brand-1 px-4 md:mb-8">
    <div class="flex max-w-7xl mx-auto py-4 items-center">
      <a href="https://www.tyltgo.com" class="text-4xl font-logo text-gray-100">Tyltgo</a>
      <p class="ml-12 text-white font-header font-bold text-2xl">Merchant PAD Setup</p>
    </div>
  </div>
  <div v-if="merchant" class="sm:mx-auto sm:w-full sm:max-w-md">
    <GoHeader :level="2" class="px-4 mt-8 text-center">
      PAD Setup for {{ merchant.businessName }} ({{ merchant.email }})
    </GoHeader>
  </div>

  <div v-if="merchant" class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
    <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10 space-y-2">
      <GoButton class="w-full" @click="startPadSetup">Start PAD Setup</GoButton>
      <!-- <GoButton class="w-full" @click="this.$router.push({ name: 'merchant.batches' })">
        Go to Batches
      </GoButton> -->
      <GoButton class="w-full" @click="logout">Logout</GoButton>
    </div>
  </div>
  <GoModal v-if="showModal" @close="showModal = false">
    <div class="p-4">
      <p class="text-lg">
        Pre-authorized Debit was setup successfully for {{ merchant.businessName }} ({{
          merchant.email
        }}).
        <br />
        <br />
        If you need to setup for another account, logout, sign-in with your other account and
        revisit this page. Contact aaron@tyltgo.com if you run into any issues.
      </p>
    </div>
    <div class="p-4">
      <div class="flex flex-row justify-between">
        <div class="flex justify-start">
          <GoButton class="text-xs" variant="link" rounded="rounded-md" @click="showModal = false">
            Close
          </GoButton>
        </div>
      </div>
    </div>
  </GoModal>
  <GoMessages />
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { Form } from 'vee-validate';
import { apiService, authService } from '@tyltgo/shared';
import startCase from 'lodash/startCase';
import { currentUser } from '@/services/auth-service';
import GoTextField from '../../components/GoTextField.vue';
import GoLabel from '../../components/GoLabel.vue';
import GoHiddenField from '../../components/GoHiddenField.vue';
import GoButton from '../../components/GoButton.vue';
import GoCheckbox from '../../components/GoCheckbox.vue';
import GoMessages from '../../components/GoMessages.vue';
import GoErrorMessage from '../../components/GoErrorMessage.vue';
import {
  addNotification,
  showLoadingMessage,
  stopLoadingMessage,
} from '../../services/ui-message-service';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const window: any;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
let stripe: any;

export default defineComponent({
  setup() {
    return {
      startCase,
      currentUser,
    };
  },
  components: {
    Form,
    GoTextField,
    GoHiddenField,
    GoButton,
    GoCheckbox,
    GoMessages,
    GoLabel,
    GoErrorMessage,
  },
  data() {
    return {
      showModal: false,
      merchant: null,
      failureMessage: null,
      creditCardErrorMessage: null,
      stripe: null,
    };
  },
  async mounted() {
    await this.getMerchantInfo();
  },
  methods: {
    async logout() {
      await authService.logout();
      this.$router.push({ name: 'merchant.login' });
    },
    async startPadSetup() {
      showLoadingMessage('Loading...');
      const stripeScript = document.createElement('script');
      stripeScript.onload = async () => {
        const keys = {
          development:
            'pk_test_51Gu81eDfXLMpF6ZZN9UTWOaNXItyXs7ivmZHQEpoLypgH7PCbTn9HnTL7H0YS2vqd54qgSCU0yHcc5BTD31iQIEU00NqqZroWI',
          staging:
            'pk_test_51Gu81eDfXLMpF6ZZN9UTWOaNXItyXs7ivmZHQEpoLypgH7PCbTn9HnTL7H0YS2vqd54qgSCU0yHcc5BTD31iQIEU00NqqZroWI',
          production: 'pk_live_51GxVABKwLGSM0KgANgwsSmXsgUvUKAEyLMYrNSma939Aovq9ieGqjUI8YTjpNcPp2nt1uELmGJV6LRh8PjhScCff00otgKRFnj',
        };

        stripe = window.Stripe(keys[import.meta.env.MODE]);
        const { setupIntent } = await apiService.merchants.billing.padSetup(this.currentUser.id);
        console.log(this.currentUser);
        try {
          const stripeSetupResponse = await stripe.confirmAcssDebitSetup(
            setupIntent.client_secret,
            {
              payment_method: {
                billing_details: {
                  name: this.currentUser.name,
                  email: this.currentUser.email,
                },
              },
            }
          );
          if (!stripeSetupResponse.error) {
            await apiService.merchants.billing.completePadSetup(
              this.currentUser.id,
              stripeSetupResponse
            );
            this.showModal = true;
          } else {
            addNotification({
              color: 'danger',
              message: stripeSetupResponse.error.message || 'An error occurred.',
            });
          }
        } catch (e) {
          console.log(e);
        }
        stopLoadingMessage();
      };
      stripeScript.setAttribute('src', 'https://js.stripe.com/v3/');
      document.body.appendChild(stripeScript);
    },
    async getMerchantInfo() {
      this.merchant = this.currentUser;
    },
  },
});
</script>
